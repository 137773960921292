import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    margin-top: 0px;
    padding: 0;
  }`

export default GlobalStyle
